import jquery from "jquery";
export default (window.$ = window.jQuery = jquery);

$(document).ready(function() {

    $(document).keydown(function(event) {
        if (event.keyCode === 27) { 
            $(".modal.is-active").removeClass("is-active");
            $(".dropdown.is-active").removeClass("is-active");
        }
    });

});
